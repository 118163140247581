<template>
  <el-dialog :lock-scroll="true" v-model="show" title="Teste">

    <template v-slot:header>
      <div  style="border-bottom: #e0e0e0 1px solid;padding: 20px;">
        <div class="modal-header" >{{KT('editAttribute.attributes_att')}}</div>
      </div>
    </template>
    <template v-slot:footer>
      <div  style="border-top: #e0e0e0 1px solid;padding: 20px;display: flex;justify-content: space-between">
        <div><el-button  type="danger" @click="doDelete()">{{KT('delete')}}</el-button></div>
        <el-button type="danger" plain @click="doCancel()">{{KT('cancel')}}</el-button>
        <el-button type="primary" @click="doSave()">{{KT('save')}}</el-button>
      </div>
    </template>

    <el-tabs v-model="tab">
      <el-tab-pane :label="KT('editAttribute.attributes_command')" name="first">
        <el-form label-width="120px" label-position="top">
          <el-form-item :label="KT('editAttribute.attributes_description')">
            <el-input v-model="formData.description"></el-input>
          </el-form-item>

          <el-form-item :label="KT('editAttribute.attributes_Attribute')">
            <el-input v-model="formData.attribute"></el-input>
          </el-form-item>

          <el-form-item :label="KT('editAttribute.attributes_typeOut')">
            
            <el-select v-model="formData.type" @change="testExp()" filterable :placeholder="KT('editAttribute.attributes_typeOut')" :size="'large'" :no-data-text="KT('editAttribute.attributes_Not')">
              
              <el-option
                  :key="1"
                  :label="KT('editAttribute.attributes_string')"
                  :value="KT('editAttribute.attributes_string')"

              >
              </el-option>


              <el-option
                  :key="2"
                  :label="KT('editAttribute.attributes_number')"
                  :value="KT('editAttribute.attributes_number')"

              >
              </el-option>


              <el-option
                  :key="3"
                  :label="KT('editAttribute.attributes_boolean')"
                  
                  :value="KT('editAttribute.attributes_boolean')"

              >
              </el-option>
            </el-select>
          </el-form-item>

          <div style="display: flex;">
            <div style="flex: 1;margin-right: 5px;">
            <el-form-item :label="KT('editAttribute.attributes_expresion')"
            >
                <el-input
                  v-model="formData.expression"
                  :autosize="{minRows: 4}"
                  type="textarea"
                  :placeholder="KT('editAttribute.attributes_Please_input')"
                  @keyup="expChanged()"
              />
            </el-form-item>
              </div>

            <div style="flex: 1;margin-left: 5px;">

              <el-form-item :label="KT('editAttribute.attributes_testar_Attribute')">
                
              <el-select v-model="deviceId" @change="testExp()" :value-key="'id'" filterable :placeholder= "KT('editAttribute.attributes_grupo')" :size='large' :no-data-text="KT('editAttribute.attributes_Not')">
                <el-option
                    :label="KT('editAttribute.attributes_não_Testar')"
                    :value="0"
                  
                >
                </el-option>
                <el-option
                    v-for="item in store.state.devices.deviceList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"

                >
                </el-option>
              </el-select>
              </el-form-item>
            <el-form-item :label="KT('editAttribute.attributes_resultado')">
              <el-input
                  disabled
                  v-model="resultExp"
                  :autosize="{minRows: 4}"
                  type="textarea"
                  :placeholder="KT('editAttribute.attributes_Please_input')"
                  
              />

              



            </el-form-item>
              </div>
          </div>


          <br>
          <el-switch
              v-model="changeNative"
              :active-text="KT('editAttribute.attributes_Devices')">
            </el-switch>

        </el-form>


      </el-tab-pane>
      
      <el-tab-pane :label="KT('editAttribute.attributes_Devices')" :name="KT('editAttribute.attributes_Devices')">
        <tab-devices ref="deviceList"></tab-devices>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>


<script setup>


import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/tab-pane/style/css'
import 'element-plus/es/components/tabs/style/css'

import {ElDialog,ElTabs,ElTabPane,ElForm,ElSwitch,ElFormItem,ElSelect,ElOption,ElButton,ElInput} from "element-plus";


import TabDevices from "./tab-devices";

const defaultAttributeData = {
  "id":0,
  "description":"",
  "attribute":"",
  "expression":"",
  "type":"string"}


import {ElNotification} from 'element-plus/es/components/notification';
import {ElMessageBox} from 'element-plus/es/components/message-box';
import {ref, defineExpose} from 'vue';
import {useStore} from 'vuex';
import KT from "../../func/kt";



const store = useStore();


const deviceList = ref(null);
const show = ref(false);
const tab = ref('first');

const changeNative = ref(false);

// eslint-disable-next-line no-undef
const formData = ref(defaultAttributeData);
const deviceId = ref(0);
const resultExp = ref('');

let timer = null;

const testExp = ()=>{
  window.$traccar.attributeTest(deviceId.value,formData.value).then(({data})=>{
    resultExp.value = data;
  }).catch((r)=>{
    resultExp.value = r.response.data;
  })
}

const expChanged = ()=>{
    if(timer){
      clearInterval(timer);
    }

    if(deviceId.value!==0) {

      timer = setTimeout(() => {
         testExp();
      }, 1000)
    }
}

const newAttribute = ()=>{
  // eslint-disable-next-line no-undef
    formData.value = JSON.parse(JSON.stringify(defaultAttributeData));
    show.value = true;
}

function decodeEntity(inputStr) {
  var textarea = document.createElement("textarea");
  textarea.innerHTML = inputStr;
  return textarea.value;
}

const editAttribute = (attribute)=>{

  // eslint-disable-next-line no-undef
  formData.value = JSON.parse(JSON.stringify(defaultAttributeData));



  for(let k of Object.keys(defaultAttributeData)){
    if(k==='expression'){

      formData.value[k] = decodeEntity(attribute[k]);
    }else {
      formData.value[k] = attribute[k];
    }
  }
  //Object.assign(formData.value,JSON.parse(JSON.stringify(attribute)));

  show.value = true;
}

defineExpose({
  newAttribute,
  editAttribute
});




const doCancel = ()=>{
  show.value = false;
}

const doDelete = ()=>{
  ElMessageBox.confirm(KT('editAttribute.attributes_Question1'), KT('editAttribute.attributes_Question2')).then(()=>{
  
        store.dispatch("attributes/delete",formData.value.id).then(()=>{
      show.value = false;
      ElNotification({
        header: KT('editAttribute.attributes_Info'),
        message: KT('editAttribute.attributes_Del'),
        type: 'info',
      });
    })
  });
}

const doSave = ()=>{

  ElNotification({
    header: KT('editAttribute.attributes_Info'),
    message: KT('editAttribute.attributes_Add'),
    
    type: 'info',
  });

  const tmp = JSON.parse(JSON.stringify(defaultAttributeData));

  Object.assign(tmp,formData.value);


  store.dispatch("attributes/save",tmp).then(async (data)=>{

    const selectedDevices = deviceList.value.selected;

    if(selectedDevices.length>0) {
      for(let device of selectedDevices){

        console.log(device);

        ElNotification({
          header: KT('editAttribute.attributes_Info'),
          message:KT('editAttribute.attributes_vincu') +device.name,
          type: 'info',
        });

        await window.$traccar.linkObjects({deviceId: device.id,attributeId: data.id});

      }
    }
    

    ElNotification({
      header: KT('editAttribute.attributes_Info'),
      message: KT('editAttribute.attributes_save'),
      type: 'success',
    });
    
    show.value = false;

  })




  /*
message: {KT('editAttribute.attributes_save')},
  tmp.name = formData.value.name;
  tmp.area = getParsedArea();

  store.dispatch("geofences/save",tmp).then(()=>{
      show.value = false;
  })*/
}


</script>

<style>

.el-button-group{
  display: flex !important;
}

.el-button-group .el-button--large{
  flex: 1 !important;
}

.el-select.el-select--large{
  width: 100%;
}

.el-dialog__header,.el-dialog__body,.el-dialog__footer{
  padding: 0px !important;
}

.el-dialog__footer{
  margin-top: 20px;
}

.el-tabs__nav-wrap{
  padding-left: 20px;
  padding-right: 20px;
}

.el-tabs__content{
  padding-left: 20px;
  padding-right: 20px;
}



.isCar{
  border: #05a7e3 1px solid !important;
}
</style>